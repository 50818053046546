import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`

    * {
        --bg-colour: #252526;
        --nav-colour: #1e1e1e;
        --font-colour-primary: #ccd6f6;
        --font-colour-secondary: white;
        --font-colour-accent: #ffab00;
        --textbox-colour: #2d2d30;
        --image-overlay-colour:#FFE380;
        
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    #root {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    html, body {
        min-height: 100%;
        width: 100%;
    }

    body {
        background-color: var(--bg-colour);
        color: var(--font-colour-primary);
        font-family: font-main;
    }


    h3{
        margin: 5px 0;
    }

    p{
        margin: 5px 0;
    }

    .gray { 
        color: #918E9B;
    }

    .bold {
        font-weight: bold;
    }

    .footer {
        flex-shrink: 0;
        margin-top: auto;
        background-color: var(--nav-colour);
        text-align: center;
        position: sticky;
        bottom: 0;
    }
`;

export default GlobalStyles;
