import React from 'react';
import styled, { css } from 'styled-components';

function Image(props) {

	const {id, link, coverImg} = props;

	const isEven = (id) => {
		return id % 2 === 0 ? true : false;
	};

	const openLink = () => {
		window.open(link);
	};

	return (
        <Container flipped={isEven(id)}
            onClick={openLink}
            style={{ cursor: 'pointer' }}
        >
            <Img src={require(`../../img/project_img/${coverImg}`)} alt="" />
        </Container>
	);
}
export default Image;

const Container = styled.div`
	position: relative;
	z-index: 0;
	background-color: var(--image-overlay-colour);
	transition: background-color 0.2s linear;
	border-radius: 3px;
	
	grid-column: 6/-1;
	grid-row: 1/-1;
	
	box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.5);
	
	@media screen and (max-width: 768px) {
		height: 100%;
	}
	
	@media (max-width: 600px) {
		grid-column: 1/-1;
		opacity: 0.2;
		box-shadow: none;
	}
	
	:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;
		background-color: #0a192f;
		mix-blend-mode: screen;
		border-radius: 2px;
	}
	
	:hover {
		background-color: transparent;
		transition: background-color 0.2s linear;
		
		img{
			filter: none;
			background-color: transparent;
			transition: background-color 0.2s linear, filter 0.2s linear;
		}
	}
	
	${({ flipped }) =>
	flipped &&
	css`
	grid-column: 1/7;
	grid-row: 1/-1;
	`}
	`;

const Img = styled.img`
	position: relative;
	border-radius: 2px;
	max-height: 100%;
	max-width: 100%;
	height: 100%;
	width: auto;
	vertical-align: middle;

	mix-blend-mode: multiply;
	filter: grayscale(100%) contrast(1);
	transition: filter 0.2s linear;

	@media screen and (max-width: 768px) {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

