import React from 'react';
import styled, { css } from 'styled-components';
import uniqid from 'uniqid'

function ProjectDescription(props) {

	const {skills, description} = props;

	const skill = skills.map((skill) => {
		return <li key={uniqid()}>{skill.toString()}</li>;
	});

	return (

        <Description>
            <p>{description} </p>
            <Skills>{skill}</Skills>
        </Description>

	);
}

export default ProjectDescription;

const Description = styled.div`
	font-size: 15px;
	line-height: 1.5;
	color: var(--font-colour-primary);

	p {
		background-color: var(--textbox-colour);
		padding: 20px 25px;
		border-radius: 2px;
		box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.5);
	}

	@media (max-width: 600px) {
		p {
			background-color: transparent;
			padding: 20px 0;
			box-shadow: none;
		}
	}
`;

const Skills = styled.ul`
	margin-top: 20px;
	padding-left: 0;
	max-width: 100%;
	overflow-x: hidden;

	li {
		margin-right: 20px;
		list-style: none;
		float: left;
		display: block;
		color: var(--font-colour-secondary);
	}
`;

