import React, {useState} from 'react';
import styled, { css } from 'styled-components';

function Hamburger(props) {

	const {isNavExpanded, setIsNavExpanded} = props;

	return (
        <Button onClick={() => setIsNavExpanded(!isNavExpanded)}>
            <svg viewBox="0 0 100 80" width="40" height="40">
                <rect width="100" height="15" rx="8"></rect>
                <rect y="30" width="100" height="15" rx="8"></rect>
                <rect y="60" width="100" height="15" rx="8"></rect>
            </svg>
        </Button>
	);
}
export default Hamburger;

const Button = styled.button`
    background: none;
    border: none;
    fill: var(--font-colour-secondary);

    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;

    :hover {
        fill: var(--font-colour-accent);
    }

	@media screen and (max-width: 600px) {
		display: block;
	}
`;