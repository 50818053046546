import React from 'react';
import project_data from '../../project_data';
import ProjectCard from './ProjectCard';
import styled, { css } from 'styled-components';

function Projects() {
	const projectCards = project_data.map((project) => {
		return <ProjectCard key={project.id} {...project} />;
	});

	return (
		<Container id="projects">
			{projectCards}
		</Container>
	);
}

export default Projects;

const Container = styled.div`
	/* padding must be at least same height as navbar */
	padding-top: 8rem;
	margin-bottom: 80px;

	@media screen and (max-width: 400px) {
		margin: 0;
	}

	> :first-child {
		margin-top: 10px;
	}
`;