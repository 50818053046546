export default [
	{
		id: 1,
		type: 'Personal Project',
		title: 'Chess',
		description:
			'A recreation of the classic game of chess. Featuring a move assistant to show the legal moves of selected pieces and alerts for when King is in check/checkmate.',
		coverImg: 'chess_game.png',
		skills: ['C#', '.NET Framework'],
		link: 'https://github.com/NCR-1/Chess-WinForms',
	},
	{
		id: 2,
		type: 'Personal Project',
		title: 'Discord Weather Bot',
		description:
			'A bot for the VoIP/messaging app Discord. The bot is capable of retrieving immediate weather inforamtion for a given location but can also schedule alerts and send notifications at a chosen time for future weather. This is accomplished by using Node.js and interacting with a MongoDB database to store notification data for users.',
		coverImg: 'discord_weather_bot.png',
		skills: ['JavaScript', 'Node.js', 'MongoDB', 'Mongoose', 'Discord.js'],
		link: 'https://github.com/NCR-1/Discord-Weather-Bot',
	},
	{
		id: 3,
		type: 'Personal Project',
		title: 'Home Media Library',
		description:
			'Home library app used for storing information about books, including data on books, genres and authors. Capable of adding, editing, and removing books from the database through the web app.',
		coverImg: 'library_app.png',
		skills: ['JavaScript', 'Node.js', 'Express', 'MongoDB', 'Pug'],
		link: 'https://github.com/NCR-1/HomeLibrary',
	},
	// {
	// 	id: 4,
	// 	type: 'Paid Project',
	// 	title: 'Local Gym Website',
	// 	description:
	// 		'A website setup for a local gym, including class timetables that can be managed through the admin dashboard as well as the ability to setup and manage memberships.',
	// 	coverImg: 'gym_site.png',
	// 	skills: ['JavaScript', 'Lots of stuff...'],
	// 	link: 'https://github.com/NCR-1',
	// },
];
