import React from 'react';
import styled, { css } from 'styled-components';
import profilePicture from '../../img/profile_picture.jpg';
import Skills from './Skills.jsx'

function AboutContent() {

	return (
            <Container>
                <MainText>
                    <p>Hi! My name is Nathan. I recently graduated from Newcastle University after completing my BSc in Chemistry.</p>
                    <br></br>
                    <p>I'm a self-taught software engineer. I began my programming journey with C# in 2020 when I decided that I needed a better way to manage my home library - this quickly turned into me learning web development so I could create a web app to make the software more accessible.</p>
                    <br></br>
                    <p>From there, I became obsessed with learning new technologies and creating highly functional and beautiful apps. I've created a range of personal projects, from Discord and Telegram bots to home media library software and board games.</p>
                    <br></br>
                    <p>I work with a range of technologies, here are a few I've been using recently:</p>
                    <br></br>
                </MainText>
                <ImgContainer>
                    <Img src={profilePicture} alt="A photo of Nathan on a mountain summit posing with arms outstretched to draw attention to the background mountain views"/>
                </ImgContainer>
                <Skills />
            </Container>
	);
}

export default AboutContent;


const Container = styled.div`
    // display: flex;

    display: grid; 
    grid-template-columns: 2fr 1fr; 
    grid-template-rows: 1fr 0fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "MainText Img"
      "Skills Skills"; 

    @media screen and (max-width: 1200px){
        flex-direction: column;
        padding: 0;

        grid-template-areas: 
        "MainText MainText"
        "Skills Img"; 
    }

    @media screen and (max-width: 600px){
        grid-template-columns: 1fr; 
        grid-template-rows: 1fr 0fr 0fr;
        
        grid-template-areas:
        "MainText"
        "Skills"
        "Img";
`

const MainText = styled.div`

    grid-area: MainText;

    p {
        margin: 0;
        font-size: 1.5rem;
        max-width: 80%;
    }

    @media screen and (max-width: 1200px){
        p {
            max-width: 100%;
        }
    }
`

const ImgContainer = styled.div`
    margin: 0 auto;
`
    
const Img = styled.img`
    grid-area: Img; 

    max-height: 300px;
    max-width: 300px;
    height: auto;
    width: auto;
    border-radius: 50%;

    @media screen and (max-width: 1200px){
        margin: auto;
        margin-top: 20px;
    }
`