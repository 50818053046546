import React from 'react';
import Hamburger from './Hamburger';
import styled, { css } from 'styled-components';
import Button from './NavResume'

function NavMenu(props) {

	const {isNavExpanded, setIsNavExpanded} = props;

	function closeNav(){
		setIsNavExpanded(false)
	}

	return (
		<Container>
			<Hamburger isNavExpanded={isNavExpanded} setIsNavExpanded={setIsNavExpanded}/>
			<List expanded = {isNavExpanded ? true : false}>
				<ListItem onClick={closeNav}>
					<Link href="#about">about</Link>
				</ListItem>
				<ListItem onClick={closeNav}>
					<Link href="#projects">projects</Link>
				</ListItem>
				<ListItem onClick={closeNav}>
					<Link href="#contact">contact</Link>
				</ListItem>
				{/* <Button isNavExpanded={isNavExpanded}/> */}
			</List>
		</Container>
	);
}
export default NavMenu;

const Container = styled.div`
	margin-left: auto;
`;

const List = styled.ul`
	display: flex;
	flex-direction: row;
	margin: 0rem 1rem;
	padding: 0;
	list-style: none;
	align-items: center;

	* {
		margin-left: 2rem;
	}

	@media screen and (max-width: 768px) {
		font-size: 1.45rem;
		* {
			margin: 0 .75rem;
		}
	}

	@media screen and (max-width: 600px) {
		flex-direction: column;
		position: absolute;
		top: 7rem;
		left: 0;
		right: 0;
		width: 100%;
		height: calc(100vh - 7rem);
		background-color: var(--nav-colour);
		display: none;
		z-index: 3;
		margin: 0;
		text-align: center;
		
		${({ expanded }) =>
		expanded &&
		css`
			display: block;
			* {
				margin: 0;
			}
		`}
	}
`;

const ListItem = styled.li`
	list-style-type: none;

	:hover {
		transform: scale(1.1);
		transition: all ease 0.25s;
	}

	@media screen and (max-width: 600px) {
		text-align: center;
		margin: 0;
  	}
`;

const Link = styled.a`
	text-decoration: none;
	display: block;
	width: 100%;

	color: var(--font-colour-secondary);

	:hover,
	:active {
		text-decoration: none;
		color: var(--font-colour-accent);
	}
	
	@media screen and (max-width: 600px) {
		width: 100%;
		padding: 1.5rem 0;
	}
`;