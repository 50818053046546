import React from 'react';
import styled, { css } from 'styled-components';
import ProjectDescription from './ProjectDescription';

function Info(props) {

	const {id, type, title, description, skills, link} = props;

    const isEven = (id) => {
		return id % 2 === 0 ? true : false;
	};

	const openLink = () => {
		window.open(link);
	};

	return (
        <Container flipped={isEven(id)}
		onClick={openLink}
		style={{ cursor: 'pointer' }}>
            <Label>{type}</Label>
            <Title>{title}</Title>
            <ProjectDescription description={description} skills={skills}/>
        </Container>
	);
}

export default Info;


const Container = styled.div`
	position: relative;
	z-index: 1;
	grid-column: 1/7;
	grid-row: 1/-1;

	@media (max-width: 600px) {
		grid-column: 1/-1;
		padding: 40px;
	}

	${({ flipped }) =>
		flipped &&
		css`
			grid-column: 6 / -1;
			grid-row: 1/-1;
			text-align: right;

			ul {
				float: right;
			}
		`}
`;

const Label = styled.p`
	color: var(--font-colour-accent);
`;

const Title = styled.h4`
	font-size: 2rem;
	margin: 10px 0 30px;
	color: var(--font-colour-secondary);
	font-family: font-heading;
`;
