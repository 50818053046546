import React, {useState} from 'react';
import logo from '../../img/N_logo.png';
import NavMenu from './NavMenu';
import styled, { css } from 'styled-components';

function Navbar() {

	const [isNavExpanded, setIsNavExpanded] = useState(false);

	return (
		<Nav expanded = {isNavExpanded ? true : false}>
			<LogoLink href="/">
				<Img src={logo} alt="logo"/>
			</LogoLink>
			<NavMenu  isNavExpanded={isNavExpanded} setIsNavExpanded={setIsNavExpanded}/>
		</Nav>
	);
}

export default Navbar;

const Nav = styled.nav`
	position: sticky;
	top: 0;
	display: flex;
	align-items: center;
	height: 7rem;
	width: 100%;
	padding: 0.5rem 3rem;
	box-sizing: border-box;
	color: black;
	box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);
	background-color: var(--nav-colour);
	justify-content: space-between;
	font-family: font-nav;
	font-size: 1.5rem;
	z-index: 999;
`;

const LogoLink = styled.a`
	display: fixed;
	margin: 0;
`;

const Img = styled.img`
	height: 65px;
	width: auto;
	margin-right: auto;

	:hover {
		animation: pulse 0.75s infinite;
		animation-timing-function: linear;
	}

	@keyframes pulse {
		0% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.1);
		}
		100% {
			transform: scale(1);
		}
	}
`;

