import React from 'react';
import styled, { css } from 'styled-components';
import Info from './ProjectInfo';
import Image from './ProjectImage';

function ProjectCard(props) {

	const {id, type, title, description, skills, coverImg, link} = props;
	
	return (
		<Project>
			<Info id={id} type={type} title={title} description={description} skills={skills} link={link}/>
			<Image id={id} coverImg={coverImg} link={link}/>
		</Project>
	);
}

export default ProjectCard;

const Project = styled.div`
	max-width: 1000px;
	margin: 200px auto 0 auto;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(12, 1fr);
	grid-template-rows: repeat(6, 1fr);
	align-items: center;
	padding: 0 10px;

	@media screen and (max-width: 768px) {
		align-items: flex-start;
		margin-left: min(15px);
		margin-right min(15px);
	}

	@media screen and (max-width: 768px) {
		align-items: flex-start;
	}

	@media screen and (max-width: 600px) {
		margin-top: 50px;
	}

	@media screen and (max-width: 400px) {
		margin: 10px 0;
	}
`;
