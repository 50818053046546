import React from 'react';
import styled, { css } from 'styled-components';

function Contact() {

	return (
        <Container id="contact">
            <Title>Get In Touch!</Title>
            <Text>My inbox is open to discuss any opportunities and projects. If you have any questions or you just wanted to say hi, contact me using the button below :)</Text>
            <Button href="mailto: nathanrymell@gmail.com" target="_blank">Contact</Button>
        </Container>
	);
}

export default Contact;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 600px;
    margin: 10rem auto;
    padding: 0 25px;

    * {
        margin .5rem;
    }
    `
    
const Title = styled.h1`
    color: var(--font-colour-accent);
    font-family: font-heading;
    font-size: 2rem;
    `
    
const Text = styled.p`
    color: var(--font-colour-primary);
    font-size: 1.25rem;
`

const Button = styled.a`

  appearance: none;
  background-color: transparent;
  border: 2px solid var(--font-colour-accent);
  border-radius: 5px;
  margin-top: 2.5rem;
  box-sizing: border-box;
  color: var(--font-colour-primary);
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  min-height: 60px;
  min-width: 0;
  outline: none;
  padding: 16px 24px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 120px;
  will-change: transform;


:hover {
  color: #fff;
  background-color: rgba(255, 171, 0, 0.2);
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

:active {
  box-shadow: none;
  transform: translateY(0);
}
`