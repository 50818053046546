import React from 'react';
import ReactDOM from 'react-dom/client';
// Components
// import CardApp from './components/cards/CardApp.jsx';
// import Footer from './components/Footer.jsx';
import Projects from './components/projects/Projects.jsx';
import Navbar from './components/navbar/Navbar.jsx';
import About from './components/about/About.jsx';
import Socials from './components/socials/Socials.jsx';
import Contact from './components/contact/contact.jsx';

// Styles
import './css/normalize.css';
import './css/styles.css';
import GlobalStyles from './css/GlobalStyles.js';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Animations won't work in <=IE9 - need to remove for incompatible browsers

root.render(
	<React.StrictMode>
		<GlobalStyles />
		<Navbar />
		<About />
		{/* <CardApp /> */}
		<Projects />
		<Contact />
		<Socials />
		{/* <Footer /> */}
	</React.StrictMode>
);
