import React from 'react';
import styled, { css } from 'styled-components';

function Skills() {

	return (
        <Container>
            <List>
                <Skill>C#</Skill>
                <Skill>.NET</Skill>
                <Skill>JavaScript</Skill>
                <Skill>Node.js</Skill>
                <Skill>MongoDB</Skill>
                <Skill>React</Skill>
            </List>
        </Container>
	);
}

export default Skills;

const Container = styled.div`

    grid-area: Skills;

    @media screen and (max-width: 1200px){
        display: flex;
        justify-content: left;
        margin-top: 20px;
    }
`

const List = styled.ul`
    display: flex;
    padding: 0;
    list-style-type: disc;
    float: left;
    list-style-position: inside;
    list-style: none;

    @media screen and (max-width: 1200px){
        // display: block;
        // -webkit-columns: 2;
        // -moz-columns: 2;
        // columns: 2;
        flex-direction: column;
        gap: 1.5rem;
    }

    @media screen and (max-width: 600px){
        display: block;
        -webkit-columns: 2;
        -moz-columns: 2;
        columns: 2;
        margin: 0 auto;
    }
`

const Skill = styled.li`
    margin: 0 1.5rem;
    padding-top: 0.5rem;

    @media screen and (min-width: 1201px){
        :first-child {
            margin-left: 0;
        }
    }

    :before{
        content: "• ";
        color: var(--font-colour-accent);
    }

    @media screen and (max-width: 400px){
    margin: 0 .75rem;

    }
`
