import React from 'react';
import styled, { css } from 'styled-components';
import AboutContent from './AboutContent.jsx'
import Skills from './Skills.jsx'

function About() {

	return (
            <Container>
                <Intro>
                    <h3>Hi, my name is</h3>
                    <h1>Nathan.</h1>
                    <h2>I'm a self-taught Software Engineer.</h2>
                    <p>I'm passionate about building beautiful interfaces and intuitive apps across the web.</p>
                </Intro>
                <AboutSection id="about">
                    <h2>About Me</h2>
                    <AboutContent />
                    {/* <Skills /> */}
                </AboutSection>
            </Container>
	);
}

export default About;


const Container = styled.section`

`

const Intro = styled.div`
    margin: 15rem 0;
    padding: 0 15%;

    * {
        padding-top: 0.5rem;
    }

    h1 {
        margin: 0;
        font-size: 5rem;
        color: var(--font-colour-secondary);
        font-family: font-heading;
    }
    
    h2 {
        margin: 0;
        font-size: 4rem;
        font-family: font-heading;
    }

    h3 {
        margin: 0;
        font-size: 1.5rem;
        color: var(--font-colour-accent);
    }
    
    p { 
        margin: 0;
        font-size: 1.5rem;
        max-width: 50%;
    }

    @media screen and (max-width: 1200px){
        padding: 0;
        width: 80%;
        margin: 15rem auto;

        p { 
            max-width: 75%;
        }
    }

    @media screen and (max-width: 768px){
        // width: 85%;
        margin-top: 2rem;
        margin-bottom: 10rem;

        h1 {
            font-size: 4rem;
        }
        
        h2 {
            font-size: 3rem;
        }
    
        h3 {
            font-size: 1.25rem;
        }

        p { 
            max-width: 75%;
        }
    }

    @media screen and (max-width: 600px){
    }
`

const AboutSection = styled.div`
    /* padding must be at least same height as navbar */
    padding-top: 8rem;
    width 70%;
    margin: 19rem auto;

    * {
        padding-top: 0.5rem;
    }

    h2 {
        color: var(--font-colour-secondary);
        font-size: 2rem;
        font-family: font-secondary;
    }

    @media screen and (max-width: 1200px){
        width 80%;
        margin: auto;
    }
`


