import React from 'react';
import styled, { css } from 'styled-components';

function NavResume(props) {

	const {isNavExpanded} = props;

	return (
        <Button href="#" expanded={isNavExpanded ? true : false}>
            cv
        </Button>
	);
}
export default NavResume;

const Button = styled.button`

    position: relative;
    padding: 0.5rem 1.5rem;
    margin-left: 2rem;
    color: var(--font-colour-secondary);
    border: 3px solid var(--font-colour-secondary);
    transition: padding 0.25s ease-in-out;
    background-color: var(--bg-colour);

	:before {
		content: "";
		position: absolute;
		top: 0.5rem;
		left: 0.5rem;
		z-index: -1;
		height: 100%;
		width: 100%;
		background-color: var(--font-colour-accent);
		border-right: 3px solid var(--font-colour-secondary);
		border-bottom: 3px solid var(--font-colour-secondary);
		transition: all 0.25s ease-in-out;
	}

	:hover {
		padding: 0.5rem 1.5rem; 
		cursor: pointer;  
	}

	:hover:before {
		top: 0;
		left: 0;
	}

	${({ expanded }) =>
	expanded &&
	css`
    	border: none;
		background: none;
		margin: 0;
		:before {
			content: none;
		}

		:hover,
		:active {
			transform: scale(1.1);
			transition: all ease 0.25s;
			color: var(--font-colour-accent);
		}
	`}
`;


